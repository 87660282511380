import { StaticImage } from 'gatsby-plugin-image'
import React,{useState} from 'react'
import ModalVideo from 'react-modal-video'

const HdfcModalVideo1 = () => {

	const [isOpen, setOpen] = useState(false)

	return (
		<React.Fragment>
			<ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="he13PUaebtc" onClose={() => setOpen(false)} />

			<button className='clickVideo' onClick={()=> setOpen(true)}>
                <div className='box position-relative'>
                    <StaticImage src="../../../images/case-studies/hdfc/hdfc-one-click.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={400} height={300}/>
                    <div className='overlay-btn d-flex align-items-center justify-content-center'>
                        <div className='y-btn'>
                            <StaticImage src="../../../images/case-studies/hdfc/play-button.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={32} height={32}/>                        
                        </div>
                    </div>
                </div>
            </button>
		</React.Fragment>
	)
}

export default HdfcModalVideo1