import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import HdfcModalVideo1 from "./hdfcModalVideo1"
import { Col, Container, Row } from "react-bootstrap"
import HdfcModalVideo2 from "./hdfcModalVideo2"
import HdfcModalVideo3 from "./hdfcModalVideo3"
import HdfcModalVideo4 from "./hdfcModalVideo4"

const HdfcResults = () => {
  return (
    <section className="case-study cs-results">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Results</h2>
                    <div className="sub-content mt-5">
                        <p>We were very excited about the results for this video. To put things in perspective let’s see how the explainer videos of PayZapp’s competitor apps performed.</p>
                        <StaticImage src="../../../images/case-studies/hdfc/competitor-video-videos.png" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={781}/>
                        <p className="py-3">Now check out the video views for our explainer video!</p>
                        <StaticImage src="../../../images/case-studies/hdfc/payzapp-video-results.png" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={800} height={350}/>
                        <p>So as you can see, 800k+ views are huge in the banking sector niche as you can clearly see from the views of the competitor apps. When we started off this project we did not expect results like this which gave us a lot of confidence that our process was successful and effective.</p>
                        <p>The app has generated over 5 million downloads on Playstore as of April 2017.</p>
                        <p>HDFC was very happy with the results and they decided to partner up with us for 7+ videos for PayZapp alone. We used the same central characters in the videos to maintain brand consistency.</p>
                        <Row>
                            <Col lg={6} className="mb-4">
                                <HdfcModalVideo1 />
                            </Col>
                            <Col lg={6} className="mb-4">
                                <HdfcModalVideo2 />
                            </Col>
                            <Col lg={6} className="mb-4">
                                <HdfcModalVideo3 />
                            </Col>
                            <Col lg={6} className="mb-4">
                                <HdfcModalVideo4 />
                            </Col>                                                                                    
                        </Row>
                        <p>Note: The original 'Introduction to Payzapp' video is currently unlisted on the HDFC bank youtube channel. They have re-uploaded the same video after adding the official HDFC logo at the end. So the view count of the video will not be the same as that which is mentioned in the Case Study.</p>
                        <p>The view count of the video was 803,777 as of 17th April 2017.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default HdfcResults
