import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import HdfcMain from "../../components/case-studies/hdfc/hdfcMain"
import HdfcInfo from "../../components/case-studies/hdfc/hdfcInfo"
import HdfcTestimonials from "../../components/case-studies/hdfc/hdfcTestimonials"
import HdfcAbout from "../../components/case-studies/hdfc/hdfcAbout"
import HdfcChallenges from "../../components/case-studies/hdfc/hdfcChallenges"
import HdfcProcess from "../../components/case-studies/hdfc/hdfcProcess"
import HdfcUse from "../../components/case-studies/hdfc/hdfcUse"
import HdfcResults from "../../components/case-studies/hdfc/hdfcResult"
import HdfcMoreCs from "../../components/case-studies/hdfc/hdfcMoreCs"
import DirectContact from "../../components/global/directContact"

import csHdfcFeaturedImage from "../../../static/case-studies/hdfc/payzapp-casestudy.jpeg"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/hdfc.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csHdfcFeaturedImage}
      title="HDFC Case Study | 2D Animation Explainer Video | Banking"
      description="How we helped HDFC Bank with an 800K view Explainer Video to promote their mobile payment solution, PayZapp"
    />
  </> 
)

const IciciBank = () => {
    return (
      <Layout>
        <Seo
          bid="cs-hdfc"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <HdfcMain />
        <HdfcInfo />
        <HdfcTestimonials />
        <HdfcAbout />
        <HdfcChallenges />
        <HdfcProcess />
        <HdfcUse />
        <HdfcResults />
        <HdfcMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default IciciBank
