import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const HdfcUse = () => {
  return (
    <section className="case-study cs-use">
        <Container>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <h2 className="font-weight-bold text-center">How HDFC Used The Video?</h2>
                    <div className="sub-content mt-5">
                        <p>We talked earlier about how explainer videos are extremely flexible in the ways it can be used. Take a look at exactly how HDFC used the video for their marketing:</p>
                        <ol>
                            <li><Link to="/blog/explainer-video-use-cases/#email">Email:</Link> By embedding videos in their E-mails, HDFC was able to directly market the video to their existing customers.</li>
                            <li><Link to="/explainer-video/#landing-page">Landing page</Link>: If a user reached the PayZapp webpage on the HDFC website, they would find the video on this page as well.</li>
                            <li>Facebook: They uploaded it on their Facebook page and ran ad campaigns to promote it.</li>
                            <li>YouTube: Apart from reaching out to the 16,000 subscribers on their Youtube channel; they also ran a Youtube ad campaign where this video was displayed as an ad targeting customers in the 22-35 year old demographic.</li>
                            <li>Whatsapp: The video was promoted through WhatsApp as well. Whatsapp has a 16MB upload limit, so we delivered a version of the video that could be sent through the app.</li>
                            <li>Playstore: When a user visits the PayZapp page on google playstore, he gets to see the explainer video there and so he can see a preview of the app which can reinforce his decision to download.</li>
                            <li>Support Chats: For customer support enquiries the video was used to address queries that could be answered by the video.</li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default HdfcUse
