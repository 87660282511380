import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const HdfcTestimonials = () => {
  return (  
    <section className="case-study testimonial">
        <Container>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <div className="t-block text-center">
                        <blockquote className="mb-5">
                            <p>"Apart from the top notch quality of their work, what really impressed me was how seamlessly we were able to navigate through the project because of their solid work process. They have a great recipe of creative storytelling and smart marketing."</p>
                        </blockquote>
                        <StaticImage src="../../../images/case-studies/hdfc/abhilasha-mathur.jpeg" alt="HDFC Bank case study Testimonials" placeholder="blurred" className="img-fluid profile-circle cs-image-min-width" width={100} height={100}/>
                        <p className="name text-center mt-3"><strong>Abhilasha Mathur</strong></p>
                        <small className="text-center">Assistant Vice President, Marketing</small>                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>     
  )
}

export default HdfcTestimonials
