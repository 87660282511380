import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import HdfcAnimation1 from "../../../images/case-studies/hdfc/animation-1.mp4"
import HdfcAnimation2 from "../../../images/case-studies/hdfc/animation-2.mp4"
import { Col, Container, Row } from "react-bootstrap"

const HdfcProcess = () => {
  return (
    <section className="case-study cs-process">
        <Container>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <h2 className="font-weight-bold text-center">Our Process</h2>
                    <div className="sub-content mt-5">
                        <p>Our account executive is the first point of contact between us and the clients.</p>
                        <p>Like we always do with our new clients, we sent out a questionnaire to HDFC when they first approached us.</p>
                        <h3>Questionnaire</h3>
                        <p><a className="click-to-tweet" href="http://ctt.ec/VXcU4" target="_blank" rel="noreferrer">Video marketing is an art and there is no one-video-fits-all format. Every video is unique to the brand in terms of its identity, customers and marketing objectives.</a></p>                        
                        <p>This is why we always encourage our clients to fill the questionnaire before sitting down for a meeting.</p>
                        <p>It has questions like:</p>
                        <StaticImage src="../../../images/case-studies/hdfc/questionnaire-1.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={800} height={458}/>
                        <StaticImage src="../../../images/case-studies/hdfc/questionnaire-2.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={458}/>
                        <p>The answers to these questions gave us important insights into the brand identity of HDFC and their target customers.</p>
                        <p>First we had our account manager and the creative director sit down for a meeting with them to discuss what they would like to achieve.</p>
                        <p>We came to an agreement that for the kind of message that they wanted to say, a 2D animated explainer video would be the best choice.</p>
                        <h3>Scripting</h3>
                        <p>The creative director had a brainstorming session with our scriptwriters on the angle of the story.</p>
                        <p>Scripting is tricky and one of the most important aspects of the video. It is about finding the right balance between a creative story and a message that drives customers to take action.</p>
                        <p><a className="click-to-tweet" href="http://ctt.ec/VXcU4" target="_blank" rel="noreferrer">Advertising legends have taught us that how we present a message is much more important than the message itself.</a></p>
                        <p>The primary target customers for PayZapp were young tech savvy individuals so we knew that the story had to appeal to this base.</p>
                        <p>In the table below you get a detailed overview of our thought process during the scripting process.</p>
                        <div className="block-four">

                            <table className="table">
                                <thead>
                                    <tr className="header">
                                        <th className="text-center"><p className="text-white mb-0">What we wanted to do</p></th>
                                        <th className="text-center"><p className="text-white mb-0">How we did it</p></th>
                                        <th className="text-center"><p className="text-white mb-0">Why we did it like that</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>
                                        <p>Tell a story that the target audience could relate to.</p>
                                    </td>

                                    <td>
                                        <p>Our target audience was tech savvy youngsters so our story was about a young couple and the scenes from their life.</p>
                                    </td>
                                    <td><p>Telling relatable stories was the best way to communicate our message to the target customers.</p></td>
                                    </tr>

                                    <tr className="even">
                                        <td><p>Highlight the customers pain points first</p></td>
                                        <td><p>We brought the attention of the viewers to the fact that online payments today are not as fast and convenient as we would hope for.</p></td>

                                        <td><p>When the pain points are highlighted first, the customers are reminded of the difficulty they face now. After that the viewers will value your solution more.</p></td>
                                    </tr>

                                    <tr>
                                        <td><p>Translate this pain point to an emotion.</p></td>
                                        <td><p>To do this we created a scene where the man is in an embarrassing situation when he is not able to pay for dinner.
                                        </p></td>

                                        <td><p>We believe that customers are motivated to purchase based on emotions and not fact. By bringing up the scene of public disturbance, the customer now feels the pain point on an emotional level.</p></td>
                                    </tr> 

                                    <tr className="even">
                                        <td><p>present how to use the app</p></td>
                                        <td><p>We explained the exact registration process and demonstrated how to make payments.
                                        </p></td>

                                        <td><p>Previewing an app before downloading shows users the look and feel of it.</p></td>
                                    </tr>                           
                                </tbody>
                            </table>
                        </div>
                        <p><a href="https://docs.google.com/document/d/1MA4c1qIGMnuAD6K15Mh8tfNXZ8vBi-KdyUYXTwWC8jU" target="_blank" rel="noreferrer">Click here to read</a> the final script for the video.</p>
                        <h3>Storyboarding</h3>
                        <p>Now that we decided how we wanted to present the message, we moved onto creating the storyboard for our video. A storyboard is a rough sketch of each frame of the video along with its description and voice over.</p>
                        <div className="block-four my-5">
                            <Row>
                                <Col lg={6} className="mb-lg-0 mb-3">
                                    <StaticImage src="../../../images/case-studies/hdfc/storyboarding-1.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={600} height={500}/>
                                </Col>
                                <Col lg={6}>
                                    <StaticImage src="../../../images/case-studies/hdfc/storyboarding-2.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={600} height={500}/>
                                </Col>                                
                            </Row>
                        </div>
                        <p><a href="https://drive.google.com/file/d/0BzauWJzaK6GGQzFRTFJSeVJCTzQ/view" target="_blank" rel="noreferrer">Click here to read</a> the final storyboard for the video.</p>
                        <h3>Illustration and style frames</h3>
                        <p>After the storyboard is created we share it with our clients for approval. After approval, it is forwarded to the illustrators to create the characters and backdrops for the video. The new illustrations makeup the style frame for the video which include the final color schemes of the video as well.</p>
                        <p>It was important that we chose the same color scheme of the HDFC for the explainer video. By using these colors we maintained brand consistency so that the audience would associate the video to the brand better.</p>
                        <div className="block-four mb-5">
                            <Row>
                                <Col lg={6} className="text-center mb-lg-0 mb-3">
                                    <h4 className="mb-3">HDFC’s Color Scheme</h4>
                                    <StaticImage src="../../../images/case-studies/hdfc/hdfc-color-scheme.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={600} height={411}/>
                                </Col>
                                <Col lg={6} className="text-center">
                                    <h4 className="mb-3">Explainer Video Color Scheme</h4>
                                    <StaticImage src="../../../images/case-studies/hdfc/explainer-video-color-scheme.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={600} height={411}/>
                                </Col>                                
                            </Row>
                        </div>
                        <p>When developing the characters we knew we wanted the characters to be a young couple as they represented our target audience best.</p>
                        <h3 className="mb-4">Character Cast:</h3>
                        <StaticImage src="../../../images/case-studies/hdfc/character-cast.png" alt="HDFC Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={2268} height={1134}/>
                        <h3>Animation</h3>
                        <p>We then forwarded the style frames to the animators who made the images come to life, frame by frame.</p>
                        <p>Animated explainer videos are effective because they engage the mind in a very visual manner. By engaging the audio and visual senses we are able to capture the attention of the viewers so that our message can be communicated to an attentive audience.</p>
                        <p>We make sure that our animations are not dry and static- As you can see that even the transitions for the PayZapp video are visually appealing and there is a great flow.</p>
                        <div className="block-four mb-5">
                            <Row>
                                <Col lg={6} className="mb-lg-0 mb-3">
                                    <video width="426" height="240" className="vid" autoPlay={true} loop muted autoplay playsinline>
                                        <source src={HdfcAnimation1} type="video/mp4" />
                                    </video>
                                </Col>
                                <Col lg={6}>
                                    <video width="426" height="240" className="vid" autoPlay={true} loop muted autoplay playsinline>
                                        <source src={HdfcAnimation2} type="video/mp4" />
                                    </video>
                                </Col>                                
                            </Row>
                        </div>
                        <p>Sound effects are also added to sync with the actions in the video (Eg: Sound of typing on phone, sound of notifications received etc.).</p>
                        <h3>Background Music</h3>
                        <p>Even though it may not seem so, music plays an important role in explainer videos.</p>
                        <p>We believe that just like the video, the music should also be custom-made as they make the video experience unique. So we produced an original background score for PayZapp.</p>
                        <p>We decided to use simple and upbeat music to capture the mood and feel of the video.</p>
                        <p>A great idea that we came up with was to make the music more peppy and upbeat just after introducing PayZapp in the video. We leveraged music to emotionally escalate the viewers.</p>
                        <p>Check it out:</p>
                        <p><iframe width="100%" title="hdfc music" height="150" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/316950745&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe></p>
                        <p><iframe width="100%"  title="hdfc music" height="150" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/316950770&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe></p>
                        <h3>Music Before Introducing The Ipal App</h3>
                        <p>The music at the beginning of the video is soft and has a monotonous aura to it. This encapsulates the mood of the scene, which is more about the pain points of the customers.</p>
                        <h3>Music After Introducing The Ipal App</h3>
                        <p>Once the Ipal app is introduced, the music picks up the tempo and is more optimistic. We wanted to convey with the music that the Ipal app was something to be thrilled about.</p>
                        <h3>Voice Over</h3>
                        <p>From our experience we now have access to a vast library of voice over talents of different nationalities, accents, gender, age groups and voice tones.</p>
                        <p>Since HDFC is an Indian bank targeting Indian customers, we knew we had to use an Indian voice over artist to better connect with the audience.</p>
                        <div className="cs-note">
                            <p className="title">NOTE</p>
                            <p>In each stage of the processes discussed above we ask for client feedback so that they have a say in each and every aspect of the <Link to="/explainer-video/#process">explainer video production process</Link>. If the client is not happy with something or if they want to include something more, we are more than happy to do whatever it takes to make the video better.</p>
                        </div>                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default HdfcProcess
