import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const HdfcChallenges = () => {
  return (
    <section className="case-study cs-challenges">
        <Container>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <h2 className="font-weight-bold text-center">CHALLENGES</h2>
                    <div className="sub-content mt-5">
                        <p>The problem was that the concept of digital payments and mobile banking were fairly new to India and the audience needed to be educated on how simple, safe and effective these payments were.</p>
                        <p>The bank had a customer base of <a href="http://www.businesstoday.in/magazine/cover-story/indias-best-ceos-2015-aditya-puri-is-transforming-hdfc-bank-into-digital-bank/story/227064.html" target="_blank" rel="noreferrer">more than 12 million users</a>.</p>
                        <ul>
                            <li>They needed to reach out to this massive audience through different channels as fast as possible.</li>
                            <li>They needed something that could easily be shared with their customers.</li>
                            <li>A way to quickly explain the product and its benefits in a short amount of time.</li>
                            <li>It should be interesting enough for busy customers to actually invest their attention.</li>
                        </ul>
                        <p>An <Link to="/explainer-video">explainer video</Link> was the perfect solution to their problem because:</p>
                        <ul>
                            <li>PayZapp’s target audience was young millennials who spent a large majority of their day on the internet. Using a digital asset like an explainer video could help HDFC reach them through various channels like social media, organic search, E-mail and WhatsApp.</li>
                            <li>This video could be used for multiple purposes like having it on the app description page on Playstore, on the HDFC E-mails, on the HDFC landing page or if they ever want to pitch the app to a investor personally.</li>
                            <li>Explainer videos make boring business concepts much more engaging both in terms of visuals and storytelling.</li>
                            <li>They can show how the app actually works so customers are aware of the look and feel of the app before downloading.</li>
                        </ul>                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default HdfcChallenges
