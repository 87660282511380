import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const HdfcAbout = () => {
  return (
    <section className="case-study">
        <Container>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <Row>
                        <Col lg={7}>
                            <div className="about-cs-product">
                                <p>PayZapp is an invoicing solution by HDFC Bank which enables customers to pay, receive and request payments on the go. E-commerce and digital payments were just starting to gain popularity in India and it was the perfect time for an app like PayZapp.</p>
                            </div>                            
                        </Col>
                        <Col lg={5}>
                            <div className="about-company">
                                <h4>Company</h4>
                                <p>HDFC Bank is India's second largest private sector lender in India by assets and the largest bank in terms of market capitalization in India in 2016. It has been included in the Forbes list of 100 most valuable global brands 8 times and is among the world’s top 10 consumer financial services firms.</p>
                                <p className="mb-4"><a href="https://www.hdfcbank.com/" target="_blank" rel="noreferrer">https://www.hdfcbank.com</a></p>
                                <StaticImage src="../../../images/case-studies/hdfc/hdfc-bank-logo.png" alt="HDFC Bank Logo | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={304} height={61}/>
                            </div>                            
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>        
  )
}

export default HdfcAbout
